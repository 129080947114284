import React from "react";
import bg from "../../../assets/img/brands/brands-bg.png";
const BrandsBanner = () => {
  return (
    <section
      className="brands-banner"
      style={{ background: `url(${bg}) no-repeat center center / cover` }}
    >
      <div className="container">
        <div className="content">
          <h2 className="title" data-aos="fade-up">
            Struggling to keep up with tiktoks,
            <span className="inline-txt">Reels,</span> and{" "}
            <span className="inline-txt">YouTube</span> Shorts?
          </h2>
          <div className="subtitle">
            License and create new content to engage your followers and amplify
            your advocates.
          </div>
        </div>
      </div>
    </section>
  );
};

export default BrandsBanner;
