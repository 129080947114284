import React from "react";
import { HashLink } from "react-router-hash-link";
import bg from "../../../assets/img/investors/keep-creativity-bg.png";
import shape from "../../../assets/img/investors/keep-shape.png";
const KeepCreativityHuman = () => {
	return (
		<section
			className="keep-creativity-section"
			style={{ background: `url(${bg}) no-repeat center center / cover` }}
		>
			<img src={shape} className="bottom--left" alt="" />
			<div className="container">
				<div className="row">
					<div className="col-lg-6">
						<div className="keep-creativity-content" data-aos="fade-up">
							<h2 className="title">Keep Creativity Human</h2>
							<p>
								We’re on a mission to use AI as an optimistic force for
								good and ensure human effort receives its fair share.
							</p>
							<HashLink
								to="#contact-us"
								className="cmn-btn bg-white text-title"
							>
								Become An Investor
							</HashLink>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default KeepCreativityHuman;
