import React, { useState } from "react";
import { FaqClose, FaqOpen } from "../../../components/Icon";

const Faqs = () => {
	return (
		<section className="faqs-section">
			<div className="container">
				<div className="faq-section-title" data-aos="fade-up">
					<h2 className="title font-extra-bold">
						FREQUENTLY ASKED QUESTIONS
					</h2>
					<div className="monetize-border"></div>
				</div>
				<div className="faq-wrapper" data-aos="fade-up">
					<FaqItems id="faqs-items" data={data} />
				</div>
			</div>
		</section>
	);
};

const FaqItems = ({ data, id }) => {
	const [open, setOpen] = useState(null);
	return (
		<div className="accordion" id={id}>
			{data?.map(({ title, text }, i) => (
				<div
					className={`accordion-item ${open == i ? "open" : ""}`}
					key={i}
				>
					<h2 className="accordion-header">
						<button
							className={`accordion-button  ${
								open === i ? "active" : ""
							}`}
							type="button"
							data-bs-toggle="collapse"
							data-bs-target={`#accordion-${id}-${i}`}
							aria-expanded="false"
							onClick={() => (open == i ? setOpen(null) : setOpen(i))}
						>
							<span className="me-2">{title} </span>
							<span className="plus ms-auto">
								<FaqOpen />
							</span>
							<span className="minus ms-auto">
								<FaqClose />
							</span>
						</button>
					</h2>
					<div
						id={`accordion-${id}-${i}`}
						className={`accordion-collapse collapse ${
							i === "0" ? "show" : ""
						}`}
						data-bs-parent={`#${id}`}
					>
						<div className="accordion-body">{text && <p>{text}</p>}</div>
					</div>
				</div>
			))}
		</div>
	);
};

const data = [
	{
		title: "How can you pay so much more?",
		text: ` Us pays more as we pass on the fees from a brand while platforms take the brand fees and pay you pennies.`,
	},
	{
		title: "Will my content be altered?",
		text: ` a watermark is added to content that goes on a brand's social stories`,
	},
	{
		title: "how does licensing work",
		text: `your content is available for use on a brand's platform for 24 hours after they initially post`,
	},
	{
		title: "how does content get selected",
		text: `brands search content by themes or direct hits ie their product being mentioned, displayed in video or captions`,
	},
	{
		title: "Will I be notified when content is live",
		text: `you will be notified when a brand asks to license your content. You have the right to refuse if you wish.`,
	},
];

export default Faqs;
