import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "../assets/img/logo.png";
import { ClearIcon, Hamburger } from "./Icon";

const Header = ({ base }) => {
  const [open, setOpen] = useState(false);
  return (
    <header className="header-section">
      <div className="container">
        <div className="header-wrapper">
          <Link to="/#" className="logo me-auto">
            <img src={logo} alt="" />
          </Link>
          <button
            className="btn p-0 border-0 outline-0 d-lg-none"
            type="button"
            onClick={() => setOpen(!open)}
          >
            <Hamburger />
          </button>
          <div className={`menu-wrapper ${open ? "active" : ""}`}>
            <button
              className="btn p-0 border-0 outline-0 d-lg-none"
              style={{ position: "absolute", left: "15px", top: "8px" }}
              onClick={() => setOpen(!open)}
            >
              <ClearIcon />
            </button>
            <ul className="menu me-lg-5">
              {menus?.map(({ name, url }, i) => (
                <li key={i}>
                  <NavLink to={url}>{name}</NavLink>
                </li>
              ))}
            </ul>
            <div className="header-btn-grp">
              <HashLink to="#contact-us" className="cmn-btn btn-outline">
                <span>Login</span>
              </HashLink>
              <HashLink
                to="#contact-us"
                className="cmn-btn"
                style={{ "--base": base ? base : "" }}
              >
                <span>Sign up</span>
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
export const menus = [
  {
    name: "Home",
    url: "/",
  },
  {
    name: "Performers",
    url: "/performers",
  },
  {
    name: "Brands",
    url: "/brands",
  },
  {
    name: "Investors",
    url: "/investors",
  },
];
export default Header;
