import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import shape from "../assets/img/footer.png";
import tiktok from "../assets/img/tiktok.png";
import { menus } from "./Header";
import { Instagram } from "./Icon";

import axios from "axios";
import toast from "react-hot-toast";
const Footer = ({ footerFormTitle }) => {
	const location = useLocation();
	const [formInfo, setFormInfo] = useState({
		Name: "",
		Email: "",
		Country: "",
		IAm: `${location.pathname.slice(1, location.pathname.length - 1)}`,
	});
	const [btnText, setBtnText] = useState("SUBMIT");

	console.log(formInfo.IAm);

	useEffect(() => {
		if (btnText === "SUBMITTING..." || btnText === "FAIELD!") {
			setTimeout(() => {
				setBtnText("SUBMIT");
			}, 5000);
		}
	}, [btnText]);

	const handleSubmit = (e) => {
		toast.loading("Submitting...", {
			id: 1,
		});
		setBtnText("SUBMITTING...");
		e.preventDefault();

		const formData = new FormData();

		for (const key in formInfo) {
			formData.append(key, formInfo[key]);
		}

		axios
			.post(
				"https://script.google.com/macros/s/AKfycbyqd1TxTFprZ9zac6T-xy8_F8h0rglTbg7oiDSvRWWDtKkvpK5x8QyMJvN6XkQOQH0E/exec",
				formData
			)
			.then(() => {
				setBtnText("REGISTERED");
				e.target.reset();
				toast.success(
					"Thanks for registration, We will back to you soon.",
					{
						id: 1,
					}
				);
			})
			.catch((error) => {
				console.log(error);
				setBtnText("FAIELD!");
				toast.error("Something went wrong!", {
					id: 1,
				});
			});
	};

	return (
		<footer>
			<div className="footer-top" id="contact-us">
				<div className="container">
					<div className="row align-items-end gy-5">
						<div className="col-lg-7 col-md-6">
							<div className="pe-md-4">
								<div className="contact-form">
									<img src={shape} className="footer-shape" alt="" />
									<form onSubmit={handleSubmit}>
										<h3 className="title">
											{footerFormTitle
												? footerFormTitle
												: "hear from us when we launch"}
										</h3>
										<div className="form-group">
											<input
												type="text"
												className="form-control form--control"
												placeholder="Name"
												onChange={(e) =>
													setFormInfo({
														...formInfo,
														Name: e.target.value,
													})
												}
											/>
										</div>
										<div className="form-group">
											<input
												type="text"
												className="form-control form--control"
												placeholder="Email"
												onChange={(e) =>
													setFormInfo({
														...formInfo,
														Email: e.target.value,
													})
												}
											/>
										</div>

										<div className="form-group">
											<select
												onChange={(e) =>
													setFormInfo({
														...formInfo,
														Country: e.target.value,
													})
												}
												className="form-select form--control"
											>
												<option>Country</option>
												{countries.map((item, i) => (
													<option key={i}>{item}</option>
												))}
											</select>
										</div>
										<div className="mt-5 pt-lg-70">
											<button
												type="submit"
												className="btn-white btn"
											>
												{btnText}
											</button>
										</div>
									</form>
								</div>
							</div>
						</div>
						<div className="col-lg-5 col-md-6">
							<div className="footer-links">
								<div className="item">
									<h6 className="subtitle">Quick Links</h6>
									<ul>
										{menus?.map(({ name, url }, i) => (
											<li key={i}>
												<Link to={url}>{name}</Link>
											</li>
										))}
									</ul>
								</div>
								<div className="item">
									<h6 className="subtitle">Connect with us:</h6>
									<ul className="social-icons">
										<li>
											<Link to="#" className="opacity-100">
												<img
													src={tiktok}
													style={{ width: "28px" }}
													alt=""
												/>
											</Link>
										</li>
										<li>
											<Link to="#">
												<Instagram />
											</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="footer-bottom">
					<div className="copyright">
						Copyright &copy; 2023. All rights reserved.
					</div>
					<ul className="footer-bottom-links">
						<li>
							<Link to="#">Terms of Use | </Link>
						</li>
						<li>
							<Link to="#">Privacy Policy | </Link>
						</li>
						<li>
							<Link to="#">Cookie Policy</Link>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	);
};

export default Footer;

const countries = [
	"Afghanistan",
	"Albania",
	"Algeria",
	"Andorra",
	"Angola",
	"Antigua and Barbuda",
	"Argentina",
	"Armenia",
	"Australia",
	"Austria",
	"Azerbaijan",
	"Bahamas",
	"Bahrain",
	"Bangladesh",
	"Barbados",
	"Belarus",
	"Belgium",
	"Belize",
	"Benin",
	"Bhutan",
	"Bolivia",
	"Bosnia and Herzegovina",
	"Botswana",
	"Brazil",
	"Brunei",
	"Bulgaria",
	"Burkina Faso",
	"Burundi",
	"Cabo Verde",
	"Cambodia",
	"Cameroon",
	"Canada",
	"Central African Republic",
	"Chad",
	"Chile",
	"China",
	"Colombia",
	"Comoros",
	"Congo (Congo-Brazzaville)",
	"Costa Rica",
	"Croatia",
	"Cuba",
	"Cyprus",
	"Czechia (Czech Republic)",
	"Democratic Republic of the Congo (Congo-Kinshasa)",
	"Denmark",
	"Djibouti",
	"Dominica",
	"Dominican Republic",
	"East Timor (Timor-Leste)",
	"Ecuador",
	"Egypt",
	"El Salvador",
	"Equatorial Guinea",
	"Eritrea",
	"Estonia",
	"Eswatini",
	"Ethiopia",
	"Fiji",
	"Finland",
	"France",
	"Gabon",
	"Gambia",
	"Georgia",
	"Germany",
	"Ghana",
	"Greece",
	"Grenada",
	"Guatemala",
	"Guinea",
	"Guinea-Bissau",
	"Guyana",
	"Haiti",
	"Holy See",
	"Honduras",
	"Hungary",
	"Iceland",
	"India",
	"Indonesia",
	"Iran",
	"Iraq",
	"Ireland",
	"Israel",
	"Italy",
	"Ivory Coast",
	"Jamaica",
	"Japan",
	"Jordan",
	"Kazakhstan",
	"Kenya",
	"Kiribati",
	"Kosovo",
	"Kuwait",
	"Kyrgyzstan",
	"Laos",
	"Latvia",
	"Lebanon",
	"Lesotho",
	"Liberia",
	"Libya",
	"Liechtenstein",
	"Lithuania",
	"Luxembourg",
	"Madagascar",
	"Malawi",
	"Malaysia",
	"Maldives",
	"Mali",
	"Malta",
	"Marshall Islands",
	"Mauritania",
	"Mauritius",
	"Mexico",
	"Micronesia",
	"Moldova",
	"Monaco",
	"Mongolia",
	"Montenegro",
	"Morocco",
	"Mozambique",
	"Myanmar (formerly Burma)",
	"Namibia",
	"Nauru",
	"Nepal",
	"Netherlands",
	"New Zealand",
	"Nicaragua",
	"Niger",
	"Nigeria",
	"North Korea",
	"North Macedonia (formerly Macedonia)",
	"Norway",
	"Oman",
	"Pakistan",
	"Palau",
	"Palestine State",
	"Panama",
	"Papua New Guinea",
	"Paraguay",
	"Peru",
	"Philippines",
	"Poland",
	"Portugal",
	"Qatar",
	"Romania",
	"Russia",
	"Rwanda",
	"Saint Kitts and Nevis",
	"Saint Lucia",
	"Saint Vincent and the Grenadines",
	"Samoa",
	"San Marino",
	"Sao Tome and Principe",
	"Saudi Arabia",
	"Senegal",
	"Serbia",
	"Seychelles",
	"Sierra Leone",
	"Singapore",
	"Slovakia",
	"Slovenia",
	"Solomon Islands",
	"Somalia",
	"South Africa",
	"South Korea",
	"South Sudan",
	"Spain",
	"Sri Lanka",
	"Sudan",
	"Suriname",
	"Sweden",
	"Switzerland",
	"Syria",
	"Tajikistan",
	"Tanzania",
	"Thailand",
	"Togo",
	"Tonga",
	"Trinidad and Tobago",
	"Tunisia",
	"Turkey",
	"Turkmenistan",
	"Tuvalu",
	"Uganda",
	"Ukraine",
	"United Arab Emirates",
	"United Kingdom",
	"United States of America",
	"Uruguay",
	"Uzbekistan",
	"Vanuatu",
	"Venezuela",
	"Vietnam",
	"Yemen",
	"Zambia",
	"Zimbabwe",
];
