import React from "react";

const HowItWorks = () => {
  return (
    <section className="how-section">
      <div className="container">
        <div className="how-section-title mb-4 mb-md-5" data-aos="fade-up">
          <h2 className="title">How It Works</h2>
          <p>We use AI to create a bigger pie.</p>
        </div>
        <div
          className="row g-4"
          style={{ "--bs-gutter-x": "32px" }}
          data-aos="fade-up"
        >
          {data?.map(({ step, title, text }, i) => (
            <div key={i} className="col-lg-3 col-sm-6">
              <div className={`how-item ${i % 2 == 1 ? "even" : ""}`}>
                <span className="step">{step}</span>
                <h6 className="title">{title}</h6>
                <div
                  className="d-flex align-items-end"
                  style={{ minHeight: "50px" }}
                >
                  <div className="w-100">
                    <p>{text}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const data = [
  {
    step: "step 01",
    title: "Sign Up",
    text: "Link to your video library on socials or portfolio so brands can discover your style.",
  },
  {
    step: "step 02",
    title: "Receive requests ",
    text: "Brands are matched with you depending on their brief.",
  },
  {
    step: "step 03",
    title: "Accept ",
    text: "You’ll be pinged in-app when there’s a licensing offer or perform an approved script.",
  },
  {
    step: "step 04",
    title: "perform & Get paid",
    text: "Once approved, money hits your account.",
  },
];

export default HowItWorks;
