import React from "react";
import Header from "../../components/Header";
import Banner from "./components/Banner";

const Home = () => {
	return (
		<div>
			<Header base="var(--title-2)" />
			<Banner />
		</div>
	);
};

export default Home;
