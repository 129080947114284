import React from "react";
import marketSize from "../../../assets/img/investors/market-size.png";
const MarketSize = () => {
  return (
    <section className="solution-section pt-0">
      <div className="container">
        <div className="row align-items-center g-4">
          <div className="col-lg-5 pe-lg-0" dir="rtl">
            <img src={marketSize} className="problem-img pt-md-4" alt="" />
          </div>
          <div className="col-lg-7">
            <div
              className="human-creativity-content __content py-0 font-regular ms-auto"
              style={{ maxWidth: "652px" }}
              data-aos="fade-up"
            >
              <h2 className="title no-gutter mb-4 text-center">Market size</h2>
              <ul className="list">
                <li>200 million businesses on Instagram </li>
                <li>1 billion hours of new content uploaded daily</li>
                <li>$26 billion spent on product placement </li>
                <li>
                  $21 billion ($1.7 billion in 2016) spent on influencer
                  marketing
                </li>
                <li>$2 trillion asset backed securities market</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MarketSize;
