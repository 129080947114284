import React from "react";
import problem from "../../../assets/img/investors/problem.png";
const Problem = () => {
  return (
    <section className="problem-section">
      <div className="container">
        <div className="row align-items-end g-4">
          <div className="col-lg-7 pb-lg-5" style={{ maxWidth: "700px" }}>
            <div
              className="human-creativity-content __content py-0 font-regular mx-auto"
              data-aos="fade-up"
            >
              <h2 className="title no-gutter mb-4">Problem</h2>
              <p>
                Content creation for brands is energy and capital intensive.
              </p>
              <ul className="list" data-aos="fade-up">
                <li>100s of emails and 100s of Slack messages are standard</li>
                <li>
                  Brands have no avenue to find and engage performing artists{" "}
                </li>
                <li>
                  Influencer pricing is opaque and based on vanity metrics
                </li>
                <li>Approval processes are slower than passing trends </li>
                <li>Ads don’t feel native on socials</li>
                <li>Algorithms throttle reach of branded content </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 ps-lg-0">
            <img src={problem} className="problem-img" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Problem;
