import React from "react";
import bg from "../../../assets/img/brands/human-creativity-bg.png";
import shape from "../../../assets/img/brands/human-creativity-shape.png";
import img from "../../../assets/img/brands/human-creativity.png";
const HumanCreativity = () => {
  return (
    <div
      className="overflow-hidden pb-5 pb-lg-120"
      style={{ paddingTop: "32px" }}
    >
      <section
        className="human-creativity-section"
        style={{ background: `url(${bg}) no-repeat center center / cover` }}
      >
        <img src={shape} className="bottom--left" alt="" />
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-6">
              <div className="human-creativity-content">
                <h2 className="title mb-4">Keep Creativity Human</h2>
                <p style={{ maxWidth: "509px", color: "#000000" }}>
                  We believe that humans should remain the locus of agency in an
                  AI-powered world and that the effort people put into creating
                  content should be rewarded. AI’s role is to organise a first
                  draft in advance of originality and shorten feedback loops.
                </p>
              </div>
            </div>
            <div className="col-lg-6 text-center">
              <img
                src={img}
                alt=""
                style={{ transform: "translateY(-32px)" }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HumanCreativity;
