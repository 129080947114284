import React from "react";
import Layout from "../../components/Layout";
import BrandsBanner from "./components/BrandsBanner";
import FindCreativity from "./components/FindCreativity";
import HumanCreativity from "./components/HumanCreativity";
import LicenseCreativity from "./components/LicenseCreativity";
import PayCreativity from "./components/PayCreativity";

const Brands = () => {
	return (
		<>
			<Layout>
				<BrandsBanner />
				<FindCreativity />
				<LicenseCreativity />
				<PayCreativity />
				<HumanCreativity />
			</Layout>
		</>
	);
};

export default Brands;
