import React from "react";
import { HashLink } from "react-router-hash-link";
import bg from "../../../assets/img/creators/creators-bg.png";
const CreatorsBanner = () => {
	return (
		<section
			className="creators-banner"
			style={{ background: `url(${bg}) no-repeat center center / cover` }}
		>
			<div className="container">
				<div className="content" data-aos="fade-up">
					<h1 className="title">Why Join Us</h1>
					<h2 className="subtitle">
						we're <span className="inline-txt">Creating a movement</span>{" "}
						to <span className="inline-txt"> keep creativity human</span>
					</h2>
					<HashLink to="#contact-us" className="cmn-btn">
						Join Now
					</HashLink>
				</div>
			</div>
		</section>
	);
};

export default CreatorsBanner;
