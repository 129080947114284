import React from "react";
import Layout from "../../components/Layout";
import ApprovalsOwnership from "./components/ApprovalsOwnership";
import CreatorsBanner from "./components/CreatorsBanner";
import Faqs from "./components/Faqs";
import HowItWorks from "./components/HowItWorks";
import HowMuchmake from "./components/HowMuchmake";
import JoinCommunity from "./components/JoinCommunity";
import Monetize from "./components/Monetize";

const Creators = () => {
	return (
		<Layout>
			<div className="overflow-hidden">
				<CreatorsBanner />
				<Monetize />
				<HowMuchmake />
				<ApprovalsOwnership />
				<HowItWorks />
				<JoinCommunity />
				<Faqs />
			</div>
		</Layout>
	);
};

export default Creators;
