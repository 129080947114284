import React from "react";
import concept from "../../../assets/img/investors/concept.png";
const Concept = () => {
  return (
    <section className="solution-section">
      <div className="container">
        <div className="row align-items-center g-4">
          <div className="col-lg-7">
            <div
              className="human-creativity-content __content py-0 font-regular ms-auto"
              data-aos="fade-up"
            >
              <h2 className="title no-gutter mb-4 text-center">Concept</h2>
              <p>
                An app that enables brands to license short form videos from
                influencers and create content with performing artists.
              </p>
              <ul className="list">
                <li>Wealth generation for performing artists </li>
                <li>
                  Affordable original high quality content for brands to bring
                  to market at speed
                </li>
                <li>
                  AI that reduces risk of content flops and empowers brands and
                  performers{" "}
                </li>
                <li>
                  Reduce the feast and famine gap when it comes to the arts
                </li>
              </ul>
              <p className="pt-3">
                We’re leveraging powerful macro trends with AI, the dominance of
                short form  video and a desire to buy from ethical brands to
                create a bigger pie for everyone.
              </p>
            </div>
          </div>
          <div className="col-lg-5">
            <img src={concept} className="problem-img pt-md-4" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Concept;
