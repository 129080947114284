import React from "react";
import { HashLink } from "react-router-hash-link";
import img from "../../../assets/img/creators/how-much.png";
import shape from "../../../assets/img/creators/how-shape.png";
const HowMuchmake = () => {
  return (
    <section className="approval-section overflow-hidden pt-4 pb-5">
      <img src={shape} alt="" className="how-much-shape" />
      <div className="container" data-aos="fade-up">
        <div className="row g-4 gy-5 justify-content-between align-items-center">
          <div className="col-lg-6 text-center">
            <img
              src={img}
              className="mw-100"
              style={{ width: "574px" }}
              alt=""
            />
          </div>
          <div className="col-lg-6">
            <div
              className="__content monetize-content ms-auto"
              style={{ maxWidth: "589px" }}
            >
              <h2 className="title">brand campaigns & You</h2>
              <p>
                Brands need more original high quality content than ever but
                connecting directly with performing artists is challenging.
              </p>
              <p>
                We’re here to change that and to make working with brands a
                breeze:
              </p>
              <ul className="list ps-0 font-xl-19 mb-3">
                <li>Receive approved scripts and styling guidance</li>
                <li>Focus on your performance not admin</li>
                <li>Get paid</li>
              </ul>

              <HashLink
                to="#contact-us"
                className="cmn-btn"
                style={{ textTransform: "unset" }}
              >
                Videos not views
              </HashLink>
            </div>
          </div>{" "}
        </div>
      </div>
    </section>
  );
};

export default HowMuchmake;
