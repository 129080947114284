import React from "react";
import { HashLink } from "react-router-hash-link";
import img from "../../../assets/img/brands/pay-creativity.png";
import shape1 from "../../../assets/img/brands/pay-shape.png";
const PayCreativity = () => {
  return (
    <section className="pay-creativity-section">
      <img src={shape1} className="shape1 d-none d-md-block" alt="" />
      <div className="container">
        <div className="row g-4 gy-5 justify-content-between align-items-center">
          <div className="col-lg-6 text-center" data-aos="fade-up">
            <img
              src={img}
              className="mw-100"
              style={{ width: "575px" }}
              alt=""
            />
          </div>
          <div className="col-lg-6">
            <div
              className="__content ms-lg-auto"
              style={{ maxWidth: "587px" }}
              data-aos="fade-up"
            >
              <h2 className="title text-base">
                create content with performing artists
              </h2>
              <p>
                Us partnerships generate wealth for performing artists as they
                hone their craft while providing brands with access to talented
                humans that are normally not considered for content campaigns.
              </p>
              <p>
                By using AI to do the boring bits and build trust Us materially
                shortens feedback loops, creates alignment and enables
                performing artists to create content with confidence.{" "}
              </p>
              <br className="d-none d-md-block" />
              <HashLink to="#contact-us" className="cmn-btn">
                Affordable
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PayCreativity;
