import React from "react";
import unicorn from "../../../assets/img/investors/unicorn.png";
const UnicornInProgress = () => {
  return (
    <section className="solution-section">
      <div className="container">
        <div className="human-creativity-content __content py-0 text-center">
          <h2
            className="title no-gutter mb-4 pb-3 text-center"
            data-aos="fade-up"
          >
            Unicorn in progress
          </h2>
        </div>
        <div className="row align-items-center g-4" data-aos="fade-up">
          <div className="col-lg-6">
            <div
              className="human-creativity-content __content py-0 font-regular"
              style={{ maxWidth: "538px", color: "#454545" }}
            >
              <p>We’re on a journey and we’re 0.01% of the way there. </p>
              <p>
                <strong>Where we’re at: </strong>a content licensing app. . We
                use AI for discovery, outreach and to automate the licensing
                process.
              </p>
              <p>
                <strong>Where we’re going: </strong>
                creation of a global C2B entertainment company. A world of short
                form video made by performing artists for brands which uses
                software to take the pain out of content creation
              </p>
              <p>
                <strong>Where we want to be: </strong>
                category creation. A new category which allows the financial
                trading and securitisation of portfolios of content (backed by
                the cash flows of future purchase commissions) on a liquid
                secondary market.
              </p>
              <p>
                We’re here to free humanity from our digital serfdom and create
                a bigger, more delicious and more equitable pie for everyone.
              </p>
              <p>It’s about Us.</p>
            </div>
          </div>
          <div className="col-lg-6 pe-lg-0">
            <img src={unicorn} className="mw-100 mb-lg-4 pb-lg-2" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default UnicornInProgress;
