import React from "react";
import { HashLink } from "react-router-hash-link";
import img from "../../../assets/img/brands/find-creativity.png";
const FindCreativity = () => {
  return (
    <section className="find-creativity-section">
      <div className="container">
        <div className="row g-4 gy-5 justify-content-between align-items-center">
          <div className="col-lg-5" data-aos="fade-up">
            <img src={img} className="mw-100" alt="" />
          </div>
          <div className="col-lg-6">
            <div
              className="__content ms-lg-auto"
              style={{ maxWidth: "605px" }}
              data-aos="fade-up"
            >
              <h2 className="title">Find creativity</h2>
              <p>
                Short form video dominates social feeds and viewer needs but
                finding talented performing artists to bring videos to life is a
                challenge.
              </p>
              <p>
                Us connects brands with performers from the world’s best arts
                schools and enables you to create high quality content and get
                it to market before trend pass.:
              </p>
              <ul className="list">
                <li>Discover talented performers </li>
                <li>
                  AI that generates briefs using your brand’s tone of voice
                </li>
                <li>Instantly generate editable scripts and styling notes</li>
                <li>Receive draft video in days not weeks</li>
                <li>Access transparent, standardised pricing</li>
                <li>Usage rights across social platforms </li>
              </ul>

              <HashLink to="#contact-us" className="cmn-btn">
                Intentional Discovery
              </HashLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FindCreativity;
