import React from "react";
import { HashLink } from "react-router-hash-link";
import shape from "../../../assets/img/creators/community-shapes.png";
import img from "../../../assets/img/creators/join-community.png";
const JoinCommunity = () => {
  return (
    <section className="join-community-section">
      <img
        src={shape}
        className="join-community-shape d-none d-lg-block"
        alt=""
      />
      <div className="container">
        <div className="row g-4 gy-5 justify-content-between align-items-center">
          <div className="col-lg-6 text-center">
            <img
              src={img}
              className="mw-100"
              style={{ width: "440px" }}
              alt=""
              data-aos="fade-up"
            />
          </div>
          <div className="col-lg-6">
            <div
              className="__content monetize-content pb-5 mx-auto"
              style={{ maxWidth: "503px" }}
              data-aos="fade-up"
            >
              <h2
                className="title font-extra-bold"
                style={{ maxWidth: "463px" }}
              >
                Who is us for?
                <span
                  className="monetize-border mt-lg-5"
                  style={{ background: "#0185FF" }}
                ></span>
              </h2>
              <p>
                Performing artists: theatre kids, script writers, comedians,
                circus artists, dancers and more. Us has been designed to help
                performers monetise their art as they hone their craft.
              </p>
              <HashLink to="#contact-us" className="cmn-btn px-5">
                Join Us
              </HashLink>
            </div>
          </div>{" "}
        </div>
      </div>
    </section>
  );
};

export default JoinCommunity;
