import React from "react";
import Layout from "../../components/Layout";
import BigPicture from "./components/BigPicture";
import Concept from "./components/Concept";
import KeepCreativityHuman from "./components/KeepCreativityHuman";
import MarketSize from "./components/MarketSize";
import Problem from "./components/Problem";
import Solution from "./components/Solution";
import UnicornInProgress from "./components/UnicornInProgress";

const Investors = () => {
	return (
		<Layout footerFormTitle="Learn More">
			<div className="overflow-hidden position-relative">
				<KeepCreativityHuman />
				<BigPicture />
				<Problem />
				<Solution />
				<Concept />
				<MarketSize />
				<UnicornInProgress />
			</div>
		</Layout>
	);
};

export default Investors;
