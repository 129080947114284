import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const Layout = ({ footerFormTitle, children }) => {
	return (
		<>
			<Header />
			{children}
			<Footer footerFormTitle={footerFormTitle} />
		</>
	);
};

export default Layout;
