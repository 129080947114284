import React from "react";
import bigPicture from "../../../assets/img/investors/big-picture.png";
import shape from "../../../assets/img/investors/keep-shape.png";
const BigPicture = () => {
  return (
    <section className="big-picture-section">
      <img src={shape} className="bottom--left" alt="" />
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <img
              src={bigPicture}
              className="mw-100"
              style={{ zIndex: "-2" }}
              alt=""
              data-aos="fade-up"
            />
          </div>
          <div className="col-lg-6">
            <div
              className="human-creativity-content __content py-0 font-regular mx-auto"
              style={{ maxWidth: "538px" }}
              data-aos="fade-up"
            >
              <h2 className="title">Big picture</h2>
              <br className="d-none d-md-block" />
              <p>
                Creating a bigger and more delicious pie that fairly compensates
                human artists is on Us.
              </p>
              <ul className="list">
                <li>1 billion social stories are uploaded daily</li>
                <li>96% of viewers prefer short form videos</li>
                <li>Millions of performing artists are undiscoverable</li>
                <li>AI is perfect for accelerating feedback loops</li>
              </ul>
              <p className="pt-3">
                Brands use Us to find and create content that engages their
                followers and amplifies their advocates.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BigPicture;
