import React from "react";
import shape from "../../../assets/img/investors/solution-shape.png";
import solution from "../../../assets/img/investors/solution.png";
const Solution = () => {
  return (
    <section className="solution-section">
      <img src={shape} className="bottom--left d-none d-lg-block" alt="" />
      <div className="container">
        <div className="row align-items-start g-4" data-aos="fade-up">
          <div className="col-lg-6" dir="rtl">
            <img src={solution} className="problem-img" alt="" />
          </div>
          <div className="col-lg-6">
            <div className="pt-lg-4">
              <div
                className="human-creativity-content __content py-0 font-regular ms-auto font-xl-19"
                style={{ maxWidth: "590px" }}
              >
                <h2 className="title no-gutter mb-4 text-center">Solution</h2>
                <p>An app that uses AI to:</p>
                <ul className="list">
                  <li>Enable brands to license existing influencer videos </li>
                  <li>
                    Find and engage performing artists for original content
                    creation
                  </li>
                  <li>
                    Create native briefs, scripts and styling direction with AI
                  </li>
                  <li>Shorten feedback loops and ensure alignment</li>
                  <li>Standardise pricing</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Solution;
