import React from "react";
import { HashLink } from "react-router-hash-link";
import shape1 from "../../../assets/img/creators/monitize-shape.png";
import img from "../../../assets/img/creators/monitize.png";
const Monetize = () => {
	return (
		<section className="monetize-section">
			<img src={shape1} className="monetize-shape" alt="" />
			<div className="container">
				<div className="row g-4 gy-5 justify-content-between align-items-center">
					<div className="col-lg-6">
						<div
							className="__content monetize-content pb-5"
							style={{ maxWidth: "589px" }}
							data-aos="fade-up"
						>
							<h2 className="title">
								Monetize your <br /> existing Videos
								<span className="monetize-border"></span>
							</h2>
							<p className="mb-0">You should get paid.</p>
							<p className="mb-0">For your big energy sketches. </p>
							<p className="mb-0">
								Your videos that should have gone viral.
							</p>
							<p className="mb-0">Your get ready chats.</p>
							<p className="mb-0">
								Your insane cats and everything in between.
							</p>
							<p className="mb-0">
								So join Us. License your short form videos to brands
								that want to entertain their followers.
							</p>
							<p className="mb-3">
								And join a world of ongoing royalties.
							</p>
							<HashLink to="#contact-us" className="cmn-btn">
								Royalties!
							</HashLink>
						</div>
					</div>{" "}
					<div className="col-lg-6 text-center">
						<img src={img} className="mw-100" alt="" />
					</div>
				</div>
			</div>
		</section>
	);
};

export default Monetize;
