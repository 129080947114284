import React from "react";
import { HashLink } from "react-router-hash-link";
import img from "../../../assets/img/creators/approvals-ownership.png";
const ApprovalsOwnership = () => {
  return (
    <section className="monetize-section py-5">
      <div className="container">
        <div className="row g-4 gy-5 justify-content-between align-items-center">
          <div className="col-lg-7">
            <div
              className="__content monetize-content"
              // style={{ maxWidth: "589px" }}
              data-aos="fade-up"
            >
              <h2 className="title">
                Approvals
                <span className="monetize-border"></span>
              </h2>
              <p>
                Us is about keeping creativity human and creating ways to get
                you paid in the world of AI. Brands will either want to license
                your existing content or hire you to perform an approved script
                for an upcoming campaign.
              </p>
              <p>Licensing your videos:</p>
              <ul className="list ps-0 font-xl-19">
                <li>You approve brands that request to license your content</li>
                <li>You own your content </li>
                <li>
                  Videos are used on a brand’s stories (a good time, not a long
                  time) 
                </li>
              </ul>
              <p>Brand campaigns:</p>
              <ul className="list ps-0 font-xl-19">
                <li>
                  Brands send you approved scripts, mood boards and styling
                  notes{" "}
                </li>
                <li>
                  You perform them with your nuance, personality and style{" "}
                </li>
              </ul>
              <br className="d-xl-block" />
              <HashLink to="#contact-us" className="cmn-btn px-5 ms-xl-5">
                Connect with Us
              </HashLink>
            </div>
          </div>{" "}
          <div className="col-lg-5 text-lg-end" data-aos="fade-up">
            <img src={img} className="mw-100" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default ApprovalsOwnership;
