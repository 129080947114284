import React from "react";
import { HashLink } from "react-router-hash-link";
import shape1 from "../../../assets/img/brands/creativity-shape.png";
import img from "../../../assets/img/brands/license-img.png";
const LicenseCreativity = () => {
  return (
    <section className="license-creativity-section">
      <img src={shape1} className="shape1" alt="" />
      <div className="container">
        <div className="row g-4 gy-5 justify-content-between align-items-center">
          <div className="col-lg-6">
            <div className="__content text-white" data-aos="fade-up">
              <h2 className="title text-base">license creativity</h2>
              <p>
                Licensing entertaining content from influencers is a great way
                to connect your brand with their audiences, serve entertaining
                snippets to your community and test your audience’s affinity
                with an influencer before a larger campaign. <br /> Once
                licensed, brands can:
              </p>
              <ul className="list mb-3">
                <li>Use content on organic social stories</li>
                <li>Boost content for 24 hours</li>
              </ul>
              <HashLink to="#contact-us" className="cmn-btn">
                Ethical & Legal
              </HashLink>
            </div>
          </div>
          <div className="col-lg-5" data-aos="fade-up">
            <img src={img} className="mw-100" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default LicenseCreativity;
