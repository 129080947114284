import React from "react";
import { Link } from "react-router-dom";
import img from "../../../assets/img/banner-img.png";
import shapes from "../../../assets/img/banner-shape.png";
// import img1 from "../../../assets/img/phone-1.png";
// import img2 from "../../../assets/img/phone-2.png";
const Banner = () => {
  return (
    <section className="banner-section">
      <img src={shapes} className="shapes" alt="" />
      <div className="container">
        <div className="banner-wrapper">
          <div className="banner-content" data-aos="fade-up">
            <div>
              <h1 className="subtitle">US</h1>
              <h2 className="title">An app for performing Artists & Brands</h2>
              <div className="text">
                Wealth creation for artists, content creation for brands and a
                way to keep creativity human.
              </div>

              <div className="header-btn-grp">
                <Link
                  to="/performers"
                  className="cmn-btn"
                  style={{ "--base": "var(--title-2)" }}
                >
                  <span>PERFORMERS</span>
                </Link>
                <Link to="/brands" className="cmn-btn btn-outline">
                  <span>Brands</span>
                </Link>
              </div>
            </div>
          </div>
          <div className="banner-thumb">
            <img src={img} alt="" />
            {/* <img src={img} alt="" className="d-none d-sm-block" /> */}
            {/* <div className="d-flex align-items-center flex-column gap-4 d-sm-none">
							<img src={img1} alt="" style={{ maxWidth: "220px" }} />
							<img src={img2} alt="" style={{ maxWidth: "220px" }} />
						</div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
